import React from "react";
import Splash2 from "../../Components/organisms/Splash2/Splash2";

const SplashScreen2 = () => {
  return (
    <>
      <Splash2 />
    </>
  );
};

export default SplashScreen2;
